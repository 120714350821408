import { graphql } from "gatsby"

import SunvalePage from "../components/projects/sunvale"

export default SunvalePage

export const query = graphql`
  query {
    bathroom1After: file(
      relativePath: { eq: "projects/sunvale/bathroom1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom1Before: file(
      relativePath: { eq: "projects/sunvale/bathroom1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom2After: file(
      relativePath: { eq: "projects/sunvale/bathroom2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bathroom2Before: file(
      relativePath: { eq: "projects/sunvale/bathroom2Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom1After: file(
      relativePath: { eq: "projects/sunvale/bedroom1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom2After: file(
      relativePath: { eq: "projects/sunvale/bedroom2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    bedroom1Before: file(
      relativePath: { eq: "projects/sunvale/bedroom1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom3After: file(
      relativePath: { eq: "projects/sunvale/bedroom3After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bedroom4After: file(
      relativePath: { eq: "projects/sunvale/bedroom4After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior1After: file(
      relativePath: { eq: "projects/sunvale/exterior1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior2After: file(
      relativePath: { eq: "projects/sunvale/exterior2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    exterior3After: file(
      relativePath: { eq: "projects/sunvale/exterior3After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior4After: file(
      relativePath: { eq: "projects/sunvale/exterior4After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    exterior5After: file(
      relativePath: { eq: "projects/sunvale/exterior5After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro1After: file(
      relativePath: { eq: "projects/sunvale/intro1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro1Before: file(
      relativePath: { eq: "projects/sunvale/intro1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro2After: file(
      relativePath: { eq: "projects/sunvale/intro2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 1440, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro3After: file(
      relativePath: { eq: "projects/sunvale/intro3After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro3Before: file(
      relativePath: { eq: "projects/sunvale/intro3Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro4After: file(
      relativePath: { eq: "projects/sunvale/intro4After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    intro5After: file(
      relativePath: { eq: "projects/sunvale/intro5After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen1After: file(
      relativePath: { eq: "projects/sunvale/kitchen1After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen1Before: file(
      relativePath: { eq: "projects/sunvale/kitchen1Before.jpg" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen2After: file(
      relativePath: { eq: "projects/sunvale/kitchen2After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen3After: file(
      relativePath: { eq: "projects/sunvale/kitchen3After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    kitchen4After: file(
      relativePath: { eq: "projects/sunvale/kitchen4After.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 760, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
