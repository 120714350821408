import React from "react"

import "../global-project-styles.scss"

import useExitAnimationFade from "../../../animations/useExitAnimationFade"

import useProjectImagesParallax from "../../../animations/useParallaxItems"
import useProjectRevealAnimations from "../../../animations/useProjectRevealAnimations"
import RepeatableSection from "../RepeatableSection"

import { sunvaleData } from "./sunvaleData"

import ProjectHero from "../ProjectHero"
import SunvaleImg from "../../../assets/images/projects/sunvale"
import ProjectEndContent from "../ProjectEndContent"
import SEO from "../../seo"

const Sunvale = ({ data: pageData }) => {
  useExitAnimationFade()
  useProjectImagesParallax()
  useProjectRevealAnimations()

  console.log("pageData", pageData)

  return (
    <div className="page project sunvale">
      <SEO title="Sunvale" />

      <ProjectHero
        pageData={pageData}
        heroImg="intro2After"
        title="sunvale"
        description="Charming villa in the heart of Bowenvale."
      />

      {sunvaleData.map((sectionData, index) => (
        <RepeatableSection
          pageData={pageData}
          key={index}
          section={sectionData}
        />
      ))}

      {/* intro */}
      {/* <RepeatableSection section={sunvaleData[0]} /> */}
      {/* exterior */}
      {/* <RepeatableSection section={sunvaleData[1]} /> */}
      {/* lounge */}
      {/* <RepeatableSection section={sunvaleData[2]} /> */}
      {/* Kitchen */}
      {/* <RepeatableSection section={sunvaleData[3]} /> */}

      {/* <SectionProcess /> */}
      <ProjectEndContent to="/moncks-spur/" />
    </div>
  )
}

export default Sunvale
