import SunvaleImg from "../../../assets/images/projects/sunvale"

export const sunvaleData = [
  {
    name: "intro",
    ImageComponent: SunvaleImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "",
        text: `We instantly fell in love with this charming villa in the heart of Bowenvale. 
              We were set on keeping its existing character but giving it a full facelift. `,
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "intro1Before",
        beforeImgStyle: {},
        afterImg: "intro1After",
        afterStyle: {},
      },
      { type: "after", size: "sm", afterImg: "intro5After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "intro3After" },

      { type: "after", size: "md", afterImg: "intro4After" },
    ],
  },
  {
    name: "exterior",
    ImageComponent: SunvaleImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Exterior",
        text: `The weather boards were all professionally  repainted and the two decks replaced.  `,
      },
      { type: "after", size: "md", afterImg: "exterior4After" },
      { type: "after", size: "sm", afterImg: "exterior2After" },
      { type: "after", size: "lg", afterImg: "exterior3After" },
    ],
    col2: [
      { type: "after", size: "lg", afterImg: "exterior1After" },
      {
        type: "content-text",
        text: `The property already had a beautiful garden, it just needed some TLC. Since we finished this project in winter, we never got to see the final blooming of all the pretty flowers that were delicately scattered about.`,
      },
      { type: "after", size: "md", afterImg: "exterior5After" },
    ],
  },
  {
    name: "kitchen",
    ImageComponent: SunvaleImg,
    textColumn: "right",
    col1: [
      { type: "after", size: "lg", afterImg: "kitchen2After" },

      { type: "after", size: "sm", afterImg: "kitchen4After" },
    ],
    col2: [
      {
        type: "text",
        title: "Kitchen",
        text: `We replaced the existing kitchen with a beautiful new white panelled kitchen to tie in with the existing character and charm.   Finishing it off using traditional tapware and handles. `,
      },
      {
        type: "comparison",
        size: "md",
        beforeImg: "kitchen1Before",
        beforeImgStyle: {},
        afterImg: "kitchen1After",
        afterStyle: {},
      },
    ],
  },
  {
    name: "bedroom",
    ImageComponent: SunvaleImg,
    textColumn: "left",
    col1: [
      {
        type: "text",
        title: "Bedrooms",
        text: `The bedrooms needed some TLC, so we re-lined where there was old wallpaper and laid new carpet.`,
      },
      { type: "after", size: "lg", afterImg: "bedroom4After" },
    ],
    col2: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bedroom1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bedroom1After",
        afterStyle: {},
      },
      {
        type: "content-text",
        text: `Painting them an 'off white' to lighten the rooms up,  the double glazed windows made them very cosy spaces.`,
      },
      { type: "after", size: "md", afterImg: "bedroom2After" },
    ],
  },
  {
    name: "bathroom",
    ImageComponent: SunvaleImg,
    textColumn: "right",
    col1: [
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bathroom1Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bathroom1After",
        afterStyle: {},
      },
    ],
    col2: [
      {
        type: "text",
        title: "Bathroom",
        text: `Just like the kitchen, we went with a traditional style with a modern twist. Making sure nothing looked out of place, this included a claw foot bath, rustic Italian tiles and classical tapware.`,
      },
      {
        type: "comparison",
        size: "lg",
        beforeImg: "bathroom2Before",
        beforeImgStyle: { objectFit: "contain" },
        afterImg: "bathroom2After",
        afterStyle: {},
      },
    ],
  },
]
